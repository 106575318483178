<template>
    <div>
      <client-accounting-different-invoice-report></client-accounting-different-invoice-report>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  